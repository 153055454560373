$primary: #0d122a;
$secondary: #00e78e;
$tertiary: #00cac4;
$white: #fff;
$black: #000;
$grey: #928c9b;
$lightGrey: #e5e7ea;
$background: #f7f8fa;
$error: #f0256b;
$font: 'HK Grotesk', -apple-system, BlinkMacSystemFont, Segoe UI, Roboto, Oxygen, Ubuntu, Cantarell, Fira Sans, Droid Sans, Helvetica Neue, sans-serif;

@font-face {
  font-family: 'HK Grotesk';
  src: url(../../styles/fonts/HKGrotesk-Light.woff2) format('woff2'),
    url(../../styles/fonts/HKGrotesk-Light.woff) format('woff'),
    url(../../styles/fonts/HKGrotesk-Light.ttf) format('truetype');
  font-weight: 300;
  font-display: swap;
}

@font-face {
  font-family: 'HK Grotesk';
  src: url(../../styles/fonts/HKGrotesk-LightItalic.woff2) format('woff2'),
    url(../../styles/fonts/HKGrotesk-LightItalic.woff) format('woff'),
    url(../../styles/fonts/HKGrotesk-LightItalic.ttf) format('truetype');
  font-weight: 300;
  font-style: italic;
  font-display: swap;
}

@font-face {
  font-family: 'HK Grotesk';
  src: url(../../styles/fonts/HKGrotesk-Regular.woff2) format('woff2'),
    url(../../styles/fonts/HKGrotesk-Regular.woff) format('woff'),
    url(../../styles/fonts/HKGrotesk-Regular.ttf) format('truetype');
  font-weight: 400;
  font-display: swap;
}

@font-face {
  font-family: 'HK Grotesk';
  src: url(../../styles/fonts/HKGrotesk-Italic.woff2) format('woff2'),
    url(../../styles/fonts/HKGrotesk-Italic.woff) format('woff'),
    url(../../styles/fonts/HKGrotesk-Italic.ttf) format('truetype');
  font-weight: 400;
  font-style: italic;
  font-display: swap;
}

@font-face {
  font-family: 'HK Grotesk';
  src: url(../../styles/fonts/HKGrotesk-Medium.woff2) format('woff2'),
    url(../../styles/fonts/HKGrotesk-Medium.woff) format('woff'),
    url(../../styles/fonts/HKGrotesk-Medium.ttf) format('truetype');
  font-weight: 500;
  font-display: swap;
}

@font-face {
  font-family: 'HK Grotesk';
  src: url(../../styles/fonts/HKGrotesk-MediumItalic.woff2) format('woff2'),
    url(../../styles/fonts/HKGrotesk-MediumItalic.woff) format('woff'),
    url(../../styles/fonts/HKGrotesk-MediumItalic.ttf) format('truetype');
  font-weight: 500;
  font-style: italic;
  font-display: swap;
}

@font-face {
  font-family: 'HK Grotesk';
  src: url(../../styles/fonts/HKGrotesk-SemiBold.woff2) format('woff2'),
    url(../../styles/fonts/HKGrotesk-SemiBold.woff) format('woff'),
    url(../../styles/fonts/HKGrotesk-SemiBold.ttf) format('truetype');
  font-weight: 600;
  font-display: swap;
}

@font-face {
  font-family: 'HK Grotesk';
  src: url(../../styles/fonts/HKGrotesk-SemiBoldItalic.woff2) format('woff2'),
    url(../../styles/fonts/HKGrotesk-SemiBoldItalic.woff) format('woff'),
    url(../../styles/fonts/HKGrotesk-SemiBoldItalic.ttf) format('truetype');
  font-weight: 600;
  font-style: italic;
  font-display: swap;
}

@font-face {
  font-family: 'HK Grotesk';
  src: url(../../styles/fonts/HKGrotesk-Bold.woff2) format('woff2'),
    url(../../styles/fonts/HKGrotesk-Bold.woff) format('woff'),
    url(../../styles/fonts/HKGrotesk-Bold.ttf) format('truetype');
  font-weight: 700;
  font-display: swap;
}

@font-face {
  font-family: 'HK Grotesk';
  src: url(../../styles/fonts/HKGrotesk-BoldItalic.woff2) format('woff2'),
    url(../../styles/fonts/HKGrotesk-BoldItalic.woff) format('woff'),
    url(../../styles/fonts/HKGrotesk-BoldItalic.ttf) format('truetype');
  font-weight: 700;
  font-style: italic;
  font-display: swap;
}

@font-face {
  font-family: 'HK Grotesk';
  src: url(../../styles/fonts/HKGrotesk-ExtraBold.woff2) format('woff2'),
    url(../../styles/fonts/HKGrotesk-ExtraBold.woff) format('woff'),
    url(../../styles/fonts/HKGrotesk-ExtraBold.ttf) format('truetype');
  font-weight: 800;
  font-display: swap;
}

@mixin letter-spacing($size) {
  letter-spacing: $size/1000;
}

// Media queries
@mixin range($min, $max) {
  @media (min-width: $min + 'px') and (max-width: $max + 'px') {
    @content;
  }
}

@mixin max($size) {
  @media screen and (max-width: $size + 'px') {
    @content;
  }
}

@mixin min($size) {
  @media screen and (min-width: $size + 'px') {
    @content;
  }
}

@mixin animationDelay($duration) {
  -ms-animation-delay: $duration;
  -moz-animation-delay: $duration;
  -webkit-animation-delay: $duration;
  animation-delay: $duration;
}

@mixin transition($transition) {
  -ms-transition: all $transition linear;
  -o-transition: all $transition linear;
  -moz-transition: all $transition linear;
  -webkit-transition: all $transition linear;
  transition: all $transition linear;
}

@mixin placeholder {
  .page ::-webkit-input-placeholder {
    @content;
  }

  .page :-moz-placeholder {
    @content;
  }

  .page ::-moz-placeholder {
    @content;
  }

  .page :-ms-input-placeholder {
    @content;
  }
}

// @function rem($value) {
//   $remValue: calc($value / 16) + rem;
//   @return $remValue;
// }

@include placeholder() {
  color: $grey !important;
  font-weight: 400;
}

.Toastify__toast--success {
  background: #00e78e;
}

.page a:not(.btn):not(.dropdown-toggle):not(.nav-link) {
  @include transition(0.2s);
  position: relative;

  &:before,
  &:after {
    content: '';
    position: absolute;
    transition: transform .5s ease;
  }

  &:hover,
  &:focus,
  &:active {
    text-decoration: none;
  }

  &:not(.navbar-brand):not(.no-effect) {
    &:before {
      left: 0;
      bottom: -4.5px;
      width: 100%;
      height: 2px;
      background: $primary;
      transform: scaleX(0);
      opacity: 0.2;
    }

    &:hover:before {
      transform: scaleX(1);
    }
  }
}

.logo {
  @include transition(0.2s);

  &:hover,
  &:focus,
  &:active {
    opacity: 0.7;
  }
}

.page {
  font-family: $font;
  position: relative;

  &:not(.nosidebar) {
    left: 68px;
    width: calc(100% - 68px);

    @include max(576) {
      width: 100%;
      left: 0;
      margin-bottom: 69px
    }

    .main {
      margin: 2% 0;
      padding: 0 1rem;

      @include max(576) {
        padding-bottom: 3rem;
      }
    }
  }
}

.btn {
  width: 100%;
  max-height: 3.001875rem;
  padding: 0.845rem 15px;
  border-radius: 2rem;
  font-weight: 600;
  line-height: normal;
  position: relative;
  overflow: hidden;
  @include transition(0.2s);

  &.compact {
    width: auto;
    padding-left: 2.5rem;
    padding-right: 2.5rem;

    @include max(767) {
      width: 100%;
    }
  }

  &:hover,
  &:focus,
  &:active {
    text-decoration: none;
    box-shadow: none;
  }

  &::after {
    display: none;
    content: "";
    position: absolute;
    border-radius: 50%;
    background-color: rgba(255, 255, 255, 0.25);
    width: 100px;
    height: 100px;
    margin-top: -50px;
    margin-left: -50px;
    top: 50%;
    left: 50%;
    animation: ripple 1.5s;
    opacity: 0;
  }

  &:focus:not(:active)::after {
    display: block;
  }

  &.btn-right {
    width: 90%;
    display: block;
    margin: 0 auto;

    @include min(768) {
      min-width: 12rem;
      max-width: max-content;
      margin-right: 0;
    }

    @include max(767) {
      width: 100%;
    }
  }

  &.btn-primary {
    background: $primary;
    border-color: $primary;
    color: $white;

    &:hover,
    &:focus,
    &:active,
    &:not(:disabled):not(.disabled).active,
    &:not(:disabled):not(.disabled):active {
      background: $black;
      border-color: $black;
      color: $white;
    }
  }

  &.btn-danger {
    @extend .btn-primary;
    background: $error;
    border-color: $error;

    &:hover,
    &:focus,
    &:active,
    &:not(:disabled):not(.disabled).active,
    &:not(:disabled):not(.disabled):active {
      background: $error;
      border-color: $error;
      opacity: .8;
    }
  }

  &.btn-outline-primary {
    background: transparent;
    color: $primary;
    border-color: $primary;

    &::after {
      background-color: rgba(136, 136, 136, 0.35);
    }

    &:hover {
      opacity: 0.65;
    }

    &:focus,
    &:active,
    &:not(:disabled):not(.disabled).active,
    &:not(:disabled):not(.disabled):active {
      background: transparent;
      color: $primary;
      border-color: $primary;
      opacity: 1;
    }
  }

  &.btn-outline-danger {
    @extend .btn-outline-primary;
    color: $error;
    border-color: $error;

    &:focus,
    &:active,
    &:not(:disabled):not(.disabled).active,
    &:not(:disabled):not(.disabled):active {
      color: $error;
      border-color: $error;
    }
  }
}

.customMuiTable {
  .MuiLinearProgress-root {
    background-color: $tertiary;

    .MuiLinearProgress-bar {
      background-color: $secondary;
    }
  }

  .MuiTableContainer-root {}
}

.customPagination {
  .Mui-selected {
    background-color: #00e78e !important;
    // color: #000 !important;
    color: #fff !important;
    font-weight: bold;
    text-shadow: 0 0 0.2rem #00000069;
  }
}

.navbar-light {
  padding: 0.75rem 1rem;
  flex-wrap: unset;

  >div {
    width: 96%;
  }

  .navbar-collapse {
    @include min(992) {
      display: flex;
      flex-basis: auto;
    }

    @include max(991) {
      //padding-top: 2rem;
      position: absolute;
      left: 0;
      top: 10.5vh;
      height: 89.5vh;
      width: 100%;
      padding: 1rem 1.75rem;
      background: $white;
      opacity: 0.5;
      @include transition(0.2s);

      //@media screen and (max-height: 500px) {
      //	top: 25vh;
      //	width: 100%;
      //	padding: 1rem 1.75rem;
      //	height: 100vh;
      //}
      &.collapsing {
        opacity: 0.75;
      }

      &.show {
        opacity: 1;
      }
    }
  }

  .navbar-nav {
    text-align: right;
    display: flex;
    align-items: center;
    justify-content: flex-end;

    .navbar-brand {
      padding-top: 0;
      padding-bottom: 0;
    }

    .nav-link {

      &.btn,
      &.dropdown-toggle {
        margin-left: 1.5rem;
      }

      &.btn {
        @extend .btn;
        min-width: 10.3125rem;
        width: auto;
        padding: 0.75rem 2rem;
        display: inline-block;
        // width: 100%;
      }

      &.btn-primary {
        color: $white;
      }

      &.btn-outline-primary {
        @extend .btn-outline-primary;
      }
    }

    @include max(991) {
      >li {
        width: 100%;
        text-align: left;
      }

      .nav-link {
        margin: 10px auto;
        font-size: 1.25rem;
        color: $primary;
        font-weight: 600;

        &:focus,
        &:hover {
          color: $black;
          opacity: 0.8;
        }
      }
    }
  }

  .user-nav-dropdown {
    @include max(576) {
      position: static;
      padding-left: 1.05rem;
    }

    .nav-link {
      margin: 0.5rem 0 0.5rem 1.5rem;
      padding: 0;

      @include max(576) {
        margin-left: 0;
        padding: 0;
      }
    }

    .dropdown-toggle::after {
      content: none;
    }

    .display-pic {
      width: 3rem;
      height: 3rem;
      border-radius: 100%;
      background-repeat: no-repeat;
      background-size: cover;
      background-position: center;
      @include transition(0.2s);

      &:hover,
      &:focus,
      &:active {
        opacity: 0.8;
      }
    }

    .dropdown-menu {
      box-shadow: 0 4px .5rem rgba(0, 15, 25, 0.16);
      border: 0;
      min-width: 13rem;
      margin-top: 5px;
      border-radius: .5rem;
      // transform: translateX(-65%) translateY(2.75%);
      transform: translateX(-65%);
      z-index: 11;

      @include max(576) {
        position: absolute;
        padding-top: 2rem;
        position: absolute;
        left: 0;
        width: 100%;
        padding: 0.5rem;
        height: 89.5vh;
        background: $white;
        opacity: 0.85;
        transform: none;
        box-shadow: none;
        @include transition(0.2s);

        &.show {
          opacity: 1;
        }
      }
    }

    .dropdown-item {
      font-size: 1rem;
      color: $primary;
      font-weight: 600;
      line-height: 1.4;
      padding: .75rem 1.5rem;

      &:hover,
      &:focus,
      &:active {
        background: $secondary;
      }

      @include max(991) {
        margin: 10px auto;
        font-size: 1.25rem;
        font-weight: 600;
      }
    }
  }

  .navbar-toggler {
    display: none;

    @include max(991) {
      display: block;
      border: 0;
      // margin-right: 0.5rem;
      margin-left: auto;
      margin-right: 0;
      padding-right: 0;
      padding-left: 0;
      @include transition(0.3s);

      &:focus {
        box-shadow: none;
        outline: none;
      }

      .navbar-toggler-icon {
        width: 1.65rem;
        height: 1.65rem;
        background-image: url('/other-icons/hamburger-icon.svg');
      }

      &:not(.collapsed) {
        transform: rotate(180deg);

        .navbar-toggler-icon {
          background-image: url('/other-icons/close-icon.svg');
        }
      }
    }
  }

  .nav-buttons {
    .nav-link {
      &:not(.btn):not(.dropdown-toggle) {
        font-size: 16px;
        font-weight: 600;
        text-transform: uppercase;
        line-height: 30px;
        color: #000000;
        padding: 0px 15px 0px 15px;
      }
    }

    @include max(991) {
      display: flex;
      align-items: center;
      justify-content: space-between;

      //flex-direction: row;
      .nav-link {
        &.btn {
          width: 48%;
          min-width: inherit;
          max-width: 100%;
          font-size: 1rem;
          padding: 0.87rem 1rem;
          margin-left: auto;
          margin-right: auto;

          @include max(480) {
            width: 100%;
          }

          // &:first-child {
          //     margin-left: 0;
          //     margin-right: 2%;
          // }
          // &:last-child {
          //     margin-left: 2%;
          //     margin-right: 0;
          // }
        }
      }
    }
  }
}

@keyframes ripple {
  from {
    opacity: 1;
    transform: scale(0);
  }

  to {
    opacity: 0;
    transform: scale(10);
  }
}

.orDivider {
  color: $grey;
  text-align: center;
  position: relative;
  margin-top: 1.5rem;

  &::before,
  &::after {
    content: '';
    background: $background;
    height: 2px;
    width: 40%;
    position: absolute;
    top: 50%;
    left: 0;
    transform: translate(0%, -50%);
  }

  &::after {
    left: auto;
    right: 0;
  }
}

.container {
  &.compact {
    margin: 3rem auto;
    max-width: 26rem;
    width: 92.5%;
  }

  &.wide {
    @extend .compact;
    margin: 1.5rem auto;
    max-width: 52rem;
  }

  &.small {
    @extend .compact;
    margin: 1.5rem auto;
    max-width: 54rem;
  }

  &.wider {
    @extend .wide;
    max-width: 61.005rem;
  }
}

.container-fluid {
  &.wide {
    margin: calc(2% + 15px) auto;
    width: calc(100% - 4%);
    @include max(991){
      margin: 2% auto;
      padding-left: 0;
      padding-right: 0;
    }
  }

  &.compact {
    margin: 3rem auto;
    max-width: 26rem;
    width: 92.5%;
  }
}

.main-title {
  font-size: 2rem;
  color: $primary;
  font-weight: 600;
}

#nprogress {
  pointer-events: all;

  .bar {
    height: 2px;
    background: $primary;
  }

  .spinner-icon {
    border-top-color: $primary;
    border-left-color: $primary;
  }

  .spinner {
    width: 100%;
    // height: calc(100% - 6.5rem);
    height: 100%;
    left: 0;
    top: 0;
    display: flex;
    align-items: center;
    justify-content: center;
    background: rgba(255, 255, 255, 0.65);
  }

  .spinner-icon {
    width: 2rem;
    height: 2rem;
  }
}

.btn>.spinner-grow {
  width: 1rem;
  height: 1rem;
}

:not(.btn)>.spinner-grow {
  display: block;
  margin: 1rem auto 2rem;
  background-color: $primary;
}

.color-primary {
  color: $primary !important;
}

.color-secondary {
  color: $secondary !important;
}

.play-icon {
  content: '';
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 2.5rem;
  height: 2.5rem;
  border: 3px solid $white;
  background-color: rgba(255, 255, 255, 0.4);
  border-radius: 100%;
  box-shadow: 0px 0px 20px rgba(70, 66, 66, 0.45);
  z-index: 2;

  &::before,
  &::after {
    content: '';
    position: absolute;
    top: 50%;
    left: 55%;
    transform: translate(-50%, -50%);
    width: 0.575rem;
    height: 0.575rem;
    border-top: 6px solid transparent;
    border-bottom: 6px solid transparent;
    border-left: 11px solid $white;
    border-top-left-radius: 2px;
    border-bottom-left-radius: 2px;
  }

  &::before {
    display: none;
  }
}

.pause {
  .featuredImage::before {
    background: $black;
    opacity: 0.5;

    @include max(767) {
      display: none;
    }
  }
}

.pause-icon {
  @extend .play-icon;

  &::before,
  &::after {
    display: inline-block;
    background: $white;
    width: 0.25rem;
    height: 1rem;
    border: 0;
    border-radius: 2px;
  }

  &::before {
    left: 37%;
  }

  &::after {
    left: 63%;
  }
}

.accordion-main {
  width: 100%;

  .single-card,
  .single-card.card:not(:last-of-type) {
    border: 0;
    border-radius: 0;
    border-bottom: 1px solid $lightGrey;

    .card-header {
      margin: 1.25rem 0;
      padding: .75rem 0;
      width: 100%;
      background: url('/other-icons/caret-down.svg') no-repeat right center / 1.5rem;
      background-color: transparent;
      border: 0;
      cursor: pointer;
      @include transition(0.2s);

      &:hover,
      &:focus,
      &:active {
        opacity: 0.5;
      }
    }

    .card-title {
      margin: 0 auto;
      font-size: 1.5rem;
      font-weight: 600;
      color: $primary;
      padding-right: 1.5rem;

      @include max(991) {
        line-height: 1.4;
      }
    }

    .card-body {
      padding-top: 0;
      margin-top: -.75rem;
    }

    .card-description {
      font-size: 1.25rem;
      font-weight: 500;
      color: $grey;
      margin: 5px auto 0.5rem;
      max-width: 53.5rem;
    }
  }
}

.scroll-link {
  position: relative;

  &:hover {
    transform: translateY(-1rem);
  }

  &::before {
    content: '';
    position: relative !important;
    width: 1.5rem;
    height: 1.5rem;
    background: url('/other-icons/arrow-right.svg') no-repeat center center / contain;
    transform: rotate(-90deg);
    display: inline-block;
    margin: 0 auto;
    margin-bottom: 0.85rem;
    left: 1px;
  }
}

.simple-scroll-link {
  @extend .scroll-link;
}

.page {

  .slick-prev,
  .slick-next {
    top: -29.5%;
    background-color: $background;
    width: 7.5%;
    height: 2.75rem;
    border-radius: 1.34375rem;

    &::before {
      background: url('/other-icons/arrow-right.svg') no-repeat center center / contain;
      content: '';
      font-family: $font;
      width: 1.5rem;
      height: 1.5rem;
      display: block;
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
    }

    &:not(.slick-disabled):hover {
      background: $lightGrey;
    }
  }

  .slick-prev {
    left: auto;
    right: 15%;

    &::before {
      transform: translate(-50%, -50%) rotate(-180deg);
    }
  }

  .slick-next {
    right: 5.5%;
  }

  @include max(767) {

    .slick-prev,
    .slick-next {
      width: 15%;
      right: auto;
      top: -13.75%;
      display: none !important;
    }

    .slick-prev {
      left: 4%;
    }

    .slick-next {
      left: 23%;
    }
  }

  @include max(480) {

    .slick-prev,
    .slick-next {
      width: 30%;
      right: auto;
      top: -10.75%;
    }

    .slick-prev {
      left: 4%;
    }

    .slick-next {
      left: 38.5%;
    }
  }
}

.modal {
  z-index: 10000;
}

.modal-backdrop {
  background-color: $primary;
}

.modal-body {
  p {
    word-break: break-word;
  }
}

.modal-content {
  border: 0;
  border-radius: .5rem;
}

.modal-dialog {
  margin-top: 5.75rem;

  @include min(576) {
    max-width: 24rem;

    &.modal-lg {
      max-width: 47.5rem;
      margin-top: 8.75rem;
    }
  }

  @media (max-height: 620px) {
    margin-top: 1.75rem;
  }
}

.full-height {
  min-height: 52.5vh;
}

.modal-header .close {
  position: absolute;
  top: 1rem;
  right: 1rem;
  font-weight: 100;
  color: $error;
  opacity: 1;

  &:hover,
  &:focus {
    outline: none;
    box-shadow: none;
  }
}

.alert {
  color: $primary;
  text-align: center;
  width: 100%;
  max-width: none;
  border: 0;
  border-radius: 0;

  &.alert-success{
    background: rgba(0, 231, 142, 0.295);
  }
}

.rhap_container {
  font-family: $font;
  position: fixed;
  bottom: 0;
  width: 100%;
  z-index: 4;
  right: 0;
  min-height: 6.5rem;
  display: flex;
  flex-direction: row;
  padding: 1rem 4rem;
  box-shadow: 0 -4px 10px 0 rgba(195, 192, 192, .15);
}

.rhap_header {
  margin: 0 auto;
  display: flex;
  align-items: center;
  height: auto;
  min-width: 18.5%;
}

.audioplayer-header {
  display: flex;
  align-items: center;

  @include max(800) {
    @include transition(0.2s);

    &:hover,
    &:focus {
      outline: none;
      box-shadow: none;
      opacity: .8;
    }
  }

  .cover-art {
    max-width: 100%;
    width: 4rem;
    height: 4rem;
    border-radius: .5rem;
    margin-right: 1rem;
    object-fit: cover;
    object-position: center;
    cursor: pointer;

    @include min(801) {
      pointer-events: none;
      user-select: none;
    }
  }

  .heading {
    line-height: 1.6;
    font-size: 1rem;
    font-weight: 500;
    color: $primary;
    max-width: 10rem;
    overflow: hidden;
    // text-overflow: ellipsis;
    white-space: nowrap;
    margin: 0 auto 0 0;
    box-sizing: border-box;

    .running-text {
      display: inline-block;
      padding-right: 0%;
      padding-left: 5%;
      animation: textrun 5s linear infinite;
    }

    .sticky-text {
      background: $white;
      position: relative;
      z-index: 1;
      padding-right: 0.35rem;
    }
  }

  .author-name {
    @extend .heading;
    color: $grey;
    font-weight: 300;

    .running-text {
      animation: textrun 12s linear infinite;
    }
  }
}

@keyframes textrun {
  0% {
    transform: translate(0, 0);
  }

  100% {
    transform: translate(-50%, 0);
  }
}

.rhap_time {
  color: $grey;
  font-weight: 400;
}

.rhap_main.rhap_stacked {
  display: flex;
  flex-wrap: wrap;
  flex-direction: row;
  padding: 0 3rem;
}

.rhap_stacked .rhap_controls-section {
  margin: 0 auto;
}

.rhap_additional-controls {
  flex: 0.225 0 auto;
}

.rhap_progress-indicator {
  background: $secondary;
  box-shadow: none;
}

.rhap_progress-filled {
  background-color: $secondary;
}

.rhap_download-progress {
  background-color: $lightGrey;
}

.rhap_progress-bar-show-download {
  background-color: $background;
}

.rhap_main-controls {
  transform: translateY(-0.15625rem);
}

.rhap_main-controls-button {
  margin: 0 0.375rem;
  width: auto;
}

.rhap_play-pause-button {
  width: auto;
  height: auto;
  margin: 0;
  padding: 5px;
}

.player-play-icon {
  @include transition(0.25s);

  @include min(992) {

    &:hover,
    &:focus {
      transform: scale(1.135);
    }
  }
}

.player-pause-icon {
  @extend .player-play-icon;
}

.rhap_skip-button {
  @extend .player-play-icon;

  @include min(992) {
    &:hover {
      opacity: 0.65;
    }
  }
}

.rhap_volume-button[aria-label=Unmute] {
  opacity: 0.5;
}

.modal-open .audio-popup.modal.show {
  display: flex !important;
  height: calc(100% - 6.5rem);

  .modal-dialog {
    margin: auto auto 0;
    max-height: 85vh;
  }

  .modal-content {
    border-bottom-left-radius: 0;
    border-bottom-right-radius: 0;
    max-width: 95vw;
  }
}

.audio-popup.modal.fade:not(.show) .modal-dialog {
  transform: translate3d(0, 25%, 0);
}

.audio-popup {
  .rhap_container {
    position: relative;
    padding: 0;
    min-height: 0;
    bottom: auto;
    right: auto;
    box-shadow: none;
  }

  .rhap_main.rhap_stacked {
    padding: 0;
    flex-wrap: wrap;
    flex-direction: column;
  }

  .rhap_skip-button {
    display: block;
  }

  .rhap_play-pause-button {
    @include min(992) {
      margin: 0 1rem;
    }
  }

  .rhap_progress-section {
    display: flex;
    margin-bottom: 1.75rem;
  }

  .rhap_progress-container {
    margin: 0 calc(10px + 3%);
  }

  .rhap_progress-indicator {
    width: 15px;
    height: 15px;
    top: -5.5px;
  }

  .rhap_main-controls {
    transform: none;
    flex: auto;
    margin-bottom: 3rem;
  }

  .player-play-icon,
  .rhap_skip-button,
  .player-pause-icon {
    width: 4.625rem;
    height: 4.625rem;
  }
}

.audio-player-wrap {
  padding-top: 6.5rem;
  position: relative;
}

.sticky-player-close {
  position: absolute;
  background: $lightGrey;
  width: 1.2rem;
  height: 1.2rem;
  padding: 5px;
  top: 0;
  right: 0;
  left: auto;
  cursor: pointer;
  @include transition(0.2s);

  &:hover,
  &:focus {
    background: $secondary;
    // opacity: .5;
    outline: none;
    box-shadow: none;
  }
}

.platforms-tab {
  margin-bottom: 2rem;

  .tab-link.nav-link {
    background: transparent;
    border: 1px solid $grey;
    min-width: 5rem;
    min-height: 5rem;
    margin-right: 1.75rem;
    border-radius: .5rem;
    display: flex;
    align-items: center;
    justify-content: center;
    @include transition(0.3s);
    //filter: brightness(0);
    filter: grayscale(100%);

    &.podcatcher-icon{
      a{
        &:before, &after{
          content: unset;
        }
      }
    }

    &.active,
    &:hover,
    &:focus {
      filter: none;
    }

    //.filled-color {
    //  color: #fff;
    //  @include transition(0.3s);
    //}

    &.active,
    &:hover,
    &:focus {
      border-color: rgb(174, 29, 215);
      background: rgba(174, 29, 215, 0.105);

      .filled-color {
        fill: rgb(174, 29, 215);
      }

      &#podcast-platforms-tab-overcast {
        border-color: rgb(252, 126, 13);
        background: rgba(252, 126, 13, 0.105);

        .filled-color {
          fill: rgb(252, 126, 13);
        }
      }

      &#podcast-platforms-tab-pocketCasts {
        border-color: rgb(244, 62, 55);
        background: rgba(244, 62, 55, 0.105);

        .filled-color {
          fill: rgb(244, 62, 55);
        }
      }

      &#podcast-platforms-tab-googlePodcasts {
        border-color: #0066d9;
        background: rgba(0, 101, 217, 0.08);
      }
    }
  }
}

.vimeo-wrap {
  position: relative;
  padding: 56.25% 0 0 0;
  margin-bottom: 2.75rem;

  .frame {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    border-radius: .5rem;
    overflow: hidden;
  }
}

.sticky-only-cta {
  // hidden if not sticky
  display: none;
}

.inline-buttons {
  display: flex;
  align-items: center;
  justify-content: space-between;

  .btn {
    min-width: 45.75%;
  }

  @include max(767) {
    flex-direction: column-reverse;

    .btn {
      margin-bottom: 0.75rem;
    }
  }
}

.hideScroll .scroll-wrapper,
.scroll-wrapper.hide {
  display: none !important;
}

.rss-form {
  width: 100%;
  margin-bottom: 2rem;

  .rss-form-group {
    flex: 1;
    opacity: 1;

    .rss-input {
      margin-right: 2rem;
      width: 100%;
    }
  }
}

.tooltip-inner {
  max-width: 40rem;
  text-align: left;
  padding: 1rem;
}

.info-icon {
  font-family: serif;
  font-style: italic;
  font-size: .25rem;
  font-style: italic;
  font-size: .85rem;
  background: $secondary;
  color: $white;
  width: 20px;
  height: 20px;
  display: inline-block;
  text-align: center;
  vertical-align: middle;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  border-radius: 100%;
  cursor: default;
}

.slick-track {
  display: flex !important;
  margin-left: 0;
}

.slick-slide {
  height: inherit !important;
  display: flex !important;
  justify-content: center;
  align-items: center;
}

// Responsive begin

@media (min-height: 710px) and (max-width: 767px) {

  .sticky-cta,
  .sticky-only-cta {
    position: fixed;
    background: $white;
    display: flex;
    align-items: center;
    justify-content: center;
    bottom: 0;
    width: 100%;
    left: 0;
    box-shadow: 0 0 16px rgba(14, 32, 64, 0.28);
    transform: translate(-50%, 0);
    left: 50%;
    bottom: 0%;
    height: 10%;
    z-index: 9;

    button,
    >div,
    .btn {
      width: 85.95% !important;
      max-width: 25rem;
    }

    &.podcast-subscribe-cta {
      box-shadow: 0px 9px 16px rgba(14, 32, 64, 0.28);
    }

    &.hide-mobile {
      display: none;
    }
  }

  .audio-player-active {

    .sticky-cta,
    .sticky-only-cta {
      bottom: 6.5rem;
      box-shadow: 0 -5px 8px rgba(14, 32, 64, 0.045);
      border-bottom: 1px solid rgba(229, 231, 234, 0.59);
    }
  }

}

@media (min-height: 710px) and (max-width: 530px) {
  .audio-player-active {

    .sticky-cta,
    .sticky-only-cta {
      bottom: 9.5rem;
      box-shadow: 0 -5px 8px rgba(14, 32, 64, 0.045);
      border-bottom: 1px solid rgba(229, 231, 234, 0.59);
    }
  }
}

@include min(992) {
  .only-991 {
    display: none;
  }
}

@include min(768) {
  .copy-button {
    min-width: 11.5625rem;
  }
}

@include max(1080) {
  .rhap_volume-controls {
    display: none;
  }

  .rhap_main.rhap_stacked {
    padding-left: 5rem;
    padding-right: 0;
  }

  .rhap_progress-section {
    flex: 5 1 auto;
  }
}

@include max(991) {
  .container-fluid {
    &.wide {
      &.mobile-full {
        //margin: 0 auto;
        //padding: 0;
        //width: 100%;
        position: relative;
        //> .row {
        //	margin: 0 auto;
        //}

      }
    }
  }

  .mobile-reverse {
    flex-direction: column-reverse;
  }

  .already-account-text {
    display: none;
  }
}

// 991 end


@include max(800) {
  .rhap_main.rhap_stacked {
    padding: 0 1rem;
  }

  .rhap_skip-button {
    display: none;
  }

  .rhap_container {
    padding: 1rem 2.75rem;
  }

  .player-play-icon,
  .player-pause-icon {
    width: 3rem;
    height: 3rem;
  }
}

// 800 end

@include max(767) {
  .play-icon {
    position: relative;
    top: auto;
    left: auto;
    transform: none;
    box-shadow: none;
    background: $background;
    width: 3rem;
    height: 3rem;

    &::after {
      border-left-color: $secondary;
    }
  }

  .pause-icon {
    background: $secondary;

    &::before {
      left: 40%;
    }

    &::after {
      left: 60%;
    }
  }

  .btn {
    max-height: 5rem;
  }
}

// 767 end
@include max(600) {

  .audioplayer-header .heading,
  .audioplayer-header .author-name {
    max-width: 7rem;
  }
}

@include max(530) {
  .rhap_header {
    margin: 0;
  }

  .rhap_container {
    flex-direction: column;
  }

  .audioplayer-header .heading,
  .audioplayer-header .author-name {
    max-width: 10rem;
  }
}

@include max(480) {
  .rhap_container {
    padding: 1rem;
  }

  // .rhap_header .audioplayer-header {
  // 	.heading, .author-name {
  // 		max-width: 8rem;
  // 	}
  // }
  .platforms-tab {
    justify-content: center;
    flex-direction: row;
    justify-content: space-between;

    .tab-link.nav-link {
      flex: 1;
      min-width: 0;
      max-width: 5rem;
      margin: 0 2%;
    }
  }

  //.fluid-mobile, .navbar-light .nav-buttons .nav-link.btn.fluid-mobile {
  //	width: 100%;
  //}
}

// 480 end

.form-group .form-control.is-invalid+.form-label {
  color: $grey;
}

.form-group .form-control.is-invalid {

  &:focus,
  &:active {
    +.form-label {
      color: $error;
    }
  }
}


.noHover {
  pointer-events: none;
}

.modal-dialog-wide {
  @include min(576) {
    width: 420px;
    max-width: none !important;
  }

  @include min(768) {
    width: 450px;
  }

  .btn {
    max-height: none !important;
  }
}

.custom-footer-positioning {
  position: relative;
  bottom: -25vh;

  @include min(990) {
    bottom: -35vh;
  }

  @include min(1600) {
    bottom: -45vh;
  }
}

.MuiTooltip-popper{
  z-index: 10000 !important;
}

.MuiStep-root {
  .MuiStepIcon-root {
    border-radius: 50%;

    &.Mui-completed,
    &.Mui-active {
      font-weight: bold;
      color: $secondary;
      outline: 3px solid $secondary;
      text-shadow: 0 0 0.3rem rgba(0, 0, 0, 0.415);
    }
  }
}