@import "../../../styles/scss/globals.scss";

.mainTitle {
    @extend .main-title;
    margin-bottom: 2rem;
}

.podcastList {}

.singleRow {
    margin-bottom: 3rem;

    @include max(425) {
        justify-content: center;
    }

    .singleCol {
        width: 13rem !important;
        height: auto;
        position: relative;
        margin: 15px;

        @include max(767) {
            width: 11.125rem !important;
        }

        .loader {
            position: absolute;
            top: calc(50% - 40px);
            left: calc(50% - 16px);
        }

        .podcastInfoMisisng {
            position: absolute;
            top: calc(50% - 40px);
            left: calc(50% - 40px);
        }

        .episodeTitle {
            padding: 12px 0px 0;
            margin: 0 auto;
            text-align: left;
            width: 100%;
            font-weight: 600;
            font-size: 18px;
            white-space: nowrap;
            overflow: hidden;
            text-overflow: ellipsis;
        }

        .podcastTitle {
            padding: 12px 0px;
            margin: 0 auto;
            text-align: left;
            width: 100%;
            white-space: nowrap;
            overflow: hidden;
            text-overflow: ellipsis;
        }
    }
}

:global(.purchases-wrapper) {
    .singleRow {
        display: grid;
        width: min-content;
        grid-template-columns: repeat(6, 1fr);
        margin-bottom: 1rem;

        @include max(1600) {
            grid-template-columns: repeat(4, 1fr);
        }

        @include max(1100) {
            grid-template-columns: repeat(3, 1fr);
        }

        @include max(800) {
            grid-template-columns: repeat(2, 1fr);
        }

        @include max(450) {
            grid-template-columns: repeat(1, 1fr);
        }
    }
}

.singleGrid {
    width: 13rem;
    height: auto;
    min-height: 11.125rem;
    border-radius: 8px;
    display: flex;
    align-items: flex-end;
    background-size: cover;
    background-position: center center;
    background-repeat: no-repeat;
    position: relative;
    overflow: hidden;
    background-color: $background;
    position: relative;
    @include transition(0.2s);

    &:hover,
    &:focus,
    &:active {
        opacity: 0.8;
        outline: none;
        box-shadow: none;

        .title,
        .ctaWrapper {
            // display: block;
            visibility: visible;
        }
    }

    .cover {
        width: 13rem;
        height: auto;
        min-height: 11.125rem;
        border-radius: 8px;
        display: flex;
        align-items: flex-end;
        background-size: cover;
        background-position: center center;
        background-repeat: no-repeat;
        position: relative;
        overflow: hidden;
        background-color: $background;
        position: relative;
        @include transition(0.2s);
    }

    .ctaWrapper {
        position: absolute;
        top: 0;
        right: 0;
        // width: 15px;
        // height: 15px;
        padding: 5px 8px;
        visibility: hidden;
        display: flex;
        gap: 0.5rem;
        background-color: rgba(13, 18, 42, 0.5);
        border-bottom-left-radius: 8px;

        img {
            width: 15px;
            height: 15px;
            fill: white;
            cursor: pointer;
        }
    }

    .title {
        padding: 12px 20px;
        margin: 0 auto;
        // display: none;
        visibility: hidden;
        text-align: left;
        width: 100%;
        background-color: rgba(13, 18, 42, 0.5);
        font-weight: 600;
        color: $white;
    }

    @include max(767) {
        width: 11.125rem;
    }
}

.singleGridCreateNew {
    @extend .singleGrid;
    background: $white;
    border: 2px dashed #cccfd1;
    align-items: center;
    justify-content: center;

    .title {
        padding: 1rem;
        background: transparent;
        text-align: center;
        color: $grey;
        font-weight: 500;
        position: relative;
        visibility: visible;

        &::before {
            content: '';
            position: relative;
            background: url('/other-icons/plus-icon.svg');
            width: 1.5rem;
            height: 1.5rem;
            display: block;
            margin: 0.75rem auto;
        }
    }
}

// Responsive begin

@include max(767) {
    //.singleCol {
    //    padding: 0 8px;
    //}

    .singleGrid {
        min-height: 10rem;
    }
}

.renewText {
    padding: 6px 4px;
    color: $grey;
    font-weight: 500;
    text-align: center;
}

.renewButton {
    padding-left: 0.75rem;
    padding-right: 0.75rem;
}

.gridWrapper {
    position: relative;
    display: block;

    &.square {
        padding-top: 100%;

        .singleGrid {
            position: absolute !important;
            top: 0;
            left: 0;
            height: 100%;
            width: 100%;
            min-height: auto;
        }
    }
}